<template>
  <div>
    <div class="borderColor borderColorBackground">
      <div class="d-flex align-items-center flex-wrap text-center">
        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 0 }"
          @click="selectAnalysis(0, '發電量')"
        >
          發電量
        </div>

        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 1 }"
          @click="selectAnalysis(1, '發電收入')"
        >
          發電收入
        </div>

        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 2 }"
          @click="selectAnalysis(2, '妥善率')"
        >
          妥善率
        </div>

        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 3 }"
          @click="selectAnalysis(3, 'PR值')"
        >
          PR值
        </div>

        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 4 }"
          @click="selectAnalysis(4, '監控值與電費單比較')"
          style="min-width: 200px"
        >
          監控值與電費單比較
        </div>

        <div
          class="font-weight-bold pageButton datepickerButton"
          :class="{ active: activeOrder === 5 }"
          @click="selectAnalysis(5, '資料妥善率')"
        >
          資料妥善率
        </div>
      </div>

      <div
        v-if="chartTitle !== '監控值與電費單比較'"
        class="d-flex align-items-center justify-content-center flex-wrap mb-3"
      >
        <div
          class="d-flex align-items-center mr-3 mt-3"
          style="color: var(--normaltextcolor)"
        >
          <label for="checkbox" class="m-0">選擇區間&nbsp;</label>
          <b-form-checkbox
            id="checkbox"
            v-model="chooseDate"
            class="ml-1"
          />
        </div>

        <div v-if="!chooseDate" class="d-flex align-items-center mt-3">
          起始年月 :&nbsp;
          <date-picker
            v-model="datepickerDate"
            type="month"
            placeholder="Select month"
            value-type="format"
            format="YYYY-MM"
            class="ml-1 mr-3"
            style="width: 120px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <div v-else class="d-flex align-items-center mt-3">
          <date-picker
            v-model="beginDate"
            type="month"
            placeholder="Select month"
            value-type="format"
            format="YYYY-MM"
            style="width: 120px"
            :disabled-date="disabledDateAfterToday"
          />
          <div class="mx-2">~</div>
          <date-picker
            v-model="endDate"
            type="month"
            placeholder="Select month"
            value-type="format"
            format="YYYY-MM"
            class="mr-3"
            style="width: 120px"
            :disabled-date="disabledDateAfterToday"
          />
        </div>
        <button @click="buttonConfirm" class="confirmButton mt-3">
          {{ $t("deviceStatus.confirm") }}
        </button>
      </div>
    </div>

    <component
      class="mb-4"
      :is="currentComponentName"
      :ref="currentComponentName"
      :filename="`投資分析_${chartTitle}`"
    />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import Etoday from './investComponents/Etoday.vue'
import PowerGenerationRevenue from './investComponents/PowerGenerationRevenue.vue'
import PrepareRate from './investComponents/PrepareRate.vue'
import PerformantRate from './investComponents/PR.vue'
import Monitor from './investComponents/Monitor.vue'
import DataRetention from './investComponents/DataRetention.vue'
import { disabledDateAfterToday } from '@/utils/datetime'

export default {
  name: 'InvestmentAnalysis',
  components: {
    Etoday,
    PowerGenerationRevenue,
    PrepareRate,
    PerformantRate,
    Monitor,
    DataRetention
  },
  data () {
    return {
      chartTitle: '發電量',
      activeOrder: 0,
      datepickerDate: DateTime.local()
        .minus({ months: 11 })
        .toFormat('yyyy-MM'),
      beginDate: DateTime.local().minus({ months: 11 }).toFormat('yyyy-MM'),
      endDate: DateTime.local().toFormat('yyyy-MM'),
      searchDateArr: [],
      chooseDate: false,
      confirmButton: true
    }
  },
  computed: {
    ...mapState(['factoryData']),
    currentComponentName () {
      switch (this.chartTitle) {
        case '發電量':
          return 'Etoday'
        case '發電收入':
          return 'PowerGenerationRevenue'
        case '妥善率':
          return 'PrepareRate'
        case 'PR值':
          return 'PerformantRate'
        case '監控值與電費單比較':
          return 'Monitor'
        default:
          return 'DataRetention'
      }
    }
  },
  methods: {
    ...mapMutations(['changeDataAnalysisLang']),
    ...mapActions(['asyncLastSingleDoc']),
    disabledDateAfterToday,
    selectAnalysis (selected, routerName) {
      this.activeOrder = selected
      this.chartTitle = routerName
      this.$nextTick(() => this.buttonConfirm())
    },
    buttonConfirm () {
      this.buildSearchDate()
      switch (this.chartTitle) {
        case '發電量':
          this.$refs.Etoday.getEtodayData(this.searchDateArr)
          break
        case '發電收入':
          this.$refs.PowerGenerationRevenue.getEtodayData(this.searchDateArr)
          break
        case '妥善率':
          this.$refs.PrepareRate.getAvailability(this.searchDateArr)
          break
        case 'PR值':
          this.$refs.PerformantRate.getPrData(this.searchDateArr)
          break
        case '資料妥善率':
          this.$refs.DataRetention.getRetention(this.searchDateArr)
          break
      }
    },

    buildSearchDate () {
      this.searchDateArr = []
      if (this.chooseDate) {
        const diff = DateTime.fromISO(this.endDate).diff(
          DateTime.fromISO(this.beginDate),
          'months'
        ).months
        if (diff < 0) {
          this.$swal('請選擇正確區間')
          return
        }
        for (let i = 0; i <= diff; i++) {
          this.searchDateArr.push(
            DateTime.fromISO(this.beginDate)
              .plus({ months: i })
              .toFormat('yyyy-MM')
          )
        }
      } else {
        for (let i = 0; i < 12; i++) {
          this.searchDateArr.push(
            DateTime.fromISO(this.datepickerDate)
              .plus({ months: i })
              .toFormat('yyyy-MM')
          )
        }
      }
    }
  },
  async created () {
    if (!this.factoryData.length) {
      await this.asyncLastSingleDoc(this.$route.query.siteName)
    }
  },
  async mounted () {
    this.buttonConfirm()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/customVariables.scss";
.pageButton {
  min-width: 140px;

  &:hover {
    background-color: rgba(114, 114, 114, 0.596);
  }

  &.active {
    color: white;
    background: linear-gradient(to top, $acme-blue-3, $acme-blue-1);
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    max-width: 48%;
    margin: 4px 2px;
  }
}
</style>
