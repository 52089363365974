<template>
  <div>
    <div class="borderColor borderColorBackground my-3">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="dataClassArr"
          :options="dataClass"
          switches
        />
        <button
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <div
        v-if="hasNoData"
        class="bg-white text-center"
        style="line-height: 350px"
      >
        尚無資料
      </div>
      <highcharts
        v-else
        ref="chart"
        :options="retentionChart"
        class="bg-white"
      />
    </div>
    <div class="borderColor">
      <b-table
        :items="retentionData"
        :fields="tableField"
        hover
        :responsive="true"
        :small="small"
        class="tableStyle"
      >
        <template #cell()="data">
          {{ data.value === "" ? "-" : data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import downloadCsv from 'download-csv'
import { BTable } from 'bootstrap-vue'
import { getDataRetentionApi } from '../../api'
import { dbDataHandle } from '../../store/function/common/index'
export default {
  name: 'DataRetention',
  components: {
    BTable
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      small: true,
      retentionData: [],
      tableField: [
        { key: 'date', label: '月份' },
        { key: 'retCounts', label: '資料筆數' },
        { key: 'retPct', label: '資料妥善率 (%)' }
      ],
      dataClassArr: ['資料筆數', '資料妥善率'],
      dataClass: ['資料筆數', '資料妥善率'],
      retentionChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '資料筆數'
            }
          },
          {
            title: {
              text: '資料妥善率 (%)'
            },
            opposite: true,
            max: 100,
            min: 0
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(80, 157, 179)',
            type: 'column',
            name: '資料筆數',
            data: [],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(255, 192, 0)',
            type: 'line',
            name: '資料妥善率 (%)',
            data: [],
            yAxis: 1,
            visible: true
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      retentionDataForExcel: []
    }
  },
  methods: {
    async getRetention (months) {
      this.retentionChart.series.forEach((x) => {
        x.data = []
      })
      const retentionData = await getDataRetentionApi(
        this.$route.query.fid,
        months
      )
      if (retentionData?.data?.success) {
        // 複製 retentionData、etodayDataForExcel 不能 refer 同個 data
        // renderData() 會改動 retentionData 資料
        const concatData = retentionData.data.data.reduce(
          (acc, cur) => acc.concat(cur),
          []
        )

        // 下載資料用
        this.retentionDataForExcel = concatData

        // 顯示圖表、表格用，copy 避免改動到 rawData (retentionData.data.data)
        this.retentionData = concatData.map((item) => {
          const copy = Object.assign({}, item)
          return copy
        })

        this.renderData()
      } else {
        this.hasNoData = true
      }
    },
    renderData () {
      this.retentionChart.xAxis.categories = []
      this.retentionChart.series.forEach((x) => {
        x.data = []
      })
      this.retentionData.forEach((x) => {
        this.retentionChart.xAxis.categories.push(x.date)
        // 圖表、表格 是共用 this.retentionData 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.retCounts = x.retCounts === null ? null : dbDataHandle(x.retCounts)
        x.retPct = x.retPct === null ? null : dbDataHandle(x.retPct * 100)
        this.retentionChart.series[0].data.push(x.retCounts)
        this.retentionChart.series[1].data.push(x.retPct)
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.retentionChart.chart.events = {}
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.retentionChart.xAxis.categories)
      this.retentionChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.retentionChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.retentionDataForExcel.forEach((obj) => {
        const retCounts = obj.retCounts === null ? null : obj.retCounts
        const retPct = obj.retPct === null ? null : obj.retPct * 100
        download[0].data.push(retCounts)
        download[1].data.push(retPct)
      })

      this.retentionChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      downloadCsv(downloadArr, fields, this.filename)
    }
  },
  mounted () {},
  watch: {
    dataClassArr: function (newValue, oldValue) {
      const setData = [
        {
          name: '資料筆數',
          dataName: ['資料筆數']
        },
        {
          name: '資料妥善率',
          dataName: ['資料妥善率 (%)']
        }
      ]
      if (oldValue !== newValue) {
        this.retentionChart.series.forEach((x) => {
          x.visible = false
        })
        setData.forEach((x) => {
          if (newValue.includes(x.name)) {
            x.dataName.forEach((y) => {
              this.retentionChart.series.find(
                (z) => z.name === y
              ).visible = true
            })
          }
        })
      }
    }
  }
}
</script>
