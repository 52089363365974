<template>
  <div>
    <div class="borderColor borderColorBackground my-3">
      <div class="d-flex align-items-center m-3">
        <b-form-checkbox-group
          v-model="dataClassArr"
          :options="dataClass"
          switches
        />
        <button
          class="confirmButton ml-auto"
          style="width: 120px"
          @click="downloadAnalysis"
        >
          資料下載
        </button>
      </div>
      <div
        v-if="hasNoData"
        class="bg-white text-center"
        style="line-height: 350px"
      >
        尚無資料
      </div>
      <highcharts v-else ref="chart" :options="revenueChart" class="bg-white" />
    </div>
    <div class="borderColor">
      <b-table
        :items="etodayData"
        :fields="tableField"
        hover
        :responsive="true"
        :small="true"
        class="tableStyle"
      >
        <template #cell()="data">
          {{ data.value === "" ? "-" : data.value }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import downloadCsv from 'download-csv'
import { getPowerRevenueApi } from '../../api'
import { dbDataHandle } from '../../store/function/common/index'
export default {
  name: 'PowerGenerationRevenue',
  components: {
    BTable
  },
  props: {
    filename: {
      type: String,
      default: '下載檔案'
    }
  },
  data () {
    return {
      hasNoData: false,
      etodayData: [],
      tableField: [
        { key: 'date', label: '月份' },
        { key: 'revenueGoal', label: '目標發電收入 (NTD)' },
        { key: 'revenueActual', label: '實際發電收入 (NTD)' },
        { key: 'irrGoal', label: '目標日照量 (kWh/m²)' },
        { key: 'irrActual', label: '實際日照量 (kWh/m²)' },
        { key: 'accAcpAll', label: '累計發電量 (kWh)' }
      ],
      dataClassArr: ['發電收入', '日照量', '實際累積發電量'],
      dataClass: ['發電收入', '日照量', '實際累積發電量'],
      revenueChart: {
        chart: {
          height: 350,
          backgroundColor: null,
          events: {
            load () {
              this.showLoading()
            }
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: []
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: false
        },
        yAxis: [
          {
            title: {
              text: '發電收入 (NTD)'
            }
          },
          {
            title: {
              text: '日照量 (kWh/m²)'
            },
            opposite: true
          },
          {
            title: {
              text: '累計發電量 (kWh)'
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            color: 'rgb(255, 192, 0)',
            type: 'column',
            name: '目標發電收入 (NTD)',
            data: [
              60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000,
              62000, 62000, 62000
            ],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(124,181,236)',
            type: 'column',
            name: '實際發電收入 (NTD)',
            data: [
              50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000, 50000
            ],
            yAxis: 0,
            visible: true
          },
          {
            color: 'rgb(155, 102, 196)',
            name: '目標日照量 (kWh/m²)',
            data: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110],
            yAxis: 1,
            visible: true
          },
          {
            color: 'rgb(230, 101, 146)',
            name: '實際日照量 (kWh/m²)',
            data: [100, 80, 100, 80, 90, 100, 100, 100, 100],
            yAxis: 1,
            visible: true
          },
          {
            color: 'rgb(37, 84, 128)',
            name: '實際累積發電量 (kWh)',
            data: [
              650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000,
              1050000
            ],
            yAxis: 2,
            visible: true
          }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500
              },
              chartOptions: {
                legend: {
                  layout: 'horizontal',
                  align: 'center',
                  verticalAlign: 'bottom',
                  y: 0
                }
              }
            }
          ]
        }
      },
      etodayDataForExcel: []
    }
  },
  methods: {
    async getEtodayData (months) {
      this.revenueChart.series.forEach((x) => {
        x.data = []
      })
      const etodayData = await getPowerRevenueApi(
        this.$route.query.fid,
        this.$route.query.siteName + '.json',
        months
      )
      if (etodayData?.data?.success) {
        // 複製 etodayData、etodayDataForExcel 不能 refer 同個 data
        // renderData() 會改動 etodayData 資料

        // 下載資料用
        this.etodayDataForExcel = etodayData.data.data

        // 顯示圖表、表格用，copy 避免改動到 rawData (etodayData.data.data)
        this.etodayData = etodayData.data.data.map((item) => {
          const copy = Object.assign({}, item)
          return copy
        })

        this.renderData()
      } else {
        this.hasNoData = true
      }
    },
    renderData () {
      this.revenueChart.xAxis.categories = []
      this.revenueChart.series.forEach((x) => {
        x.data = []
      })
      this.etodayData.forEach((x) => {
        this.revenueChart.xAxis.categories.push(x.date)
        // 圖表、表格 是共用 this.etodayData 資料，直接 dbDataHandle 改變原始資料，顯示處理過的數據
        x.revenueGoal =
          x.revenueGoal === null ? null : dbDataHandle(x.revenueGoal)
        x.revenueActual =
          x.revenueActual === null ? null : dbDataHandle(x.revenueActual)
        x.irrGoal = x.irrGoal === null ? null : dbDataHandle(x.irrGoal)
        x.irrActual = x.irrActual === null ? null : dbDataHandle(x.irrActual)
        x.accAcpAll = x.accAcpAll === null ? null : dbDataHandle(x.accAcpAll)
        this.revenueChart.series[0].data.push(x.revenueGoal)
        this.revenueChart.series[1].data.push(x.revenueActual)
        this.revenueChart.series[2].data.push(x.irrGoal)
        this.revenueChart.series[3].data.push(x.irrActual)
        this.revenueChart.series[4].data.push(x.accAcpAll)
      })
      this.hideHighChartLoading()
    },
    hideHighChartLoading () {
      this.$nextTick(function () {
        if (this.$refs && this.$refs.chart) {
          this.$refs.chart.chart.hideLoading()
          this.revenueChart.chart.events = {}
        }
      })
    },
    downloadAnalysis () {
      const downloadArr = []
      let fields = ['name']
      fields = fields.concat(this.revenueChart.xAxis.categories)
      this.revenueChart.series.forEach((x) => {
        downloadArr.push({ name: x.name })
      })

      const download = []
      this.revenueChart.series.forEach((_) => {
        download.push({ data: [] })
      })

      this.etodayDataForExcel.forEach((obj) => {
        const revenueGoal = obj.revenueGoal === null ? null : obj.revenueGoal
        const revenueActual =
          obj.revenueActual === null ? null : obj.revenueActual
        const irrGoal = obj.irrGoal === null ? null : obj.irrGoal
        const irrActual = obj.irrActual === null ? null : obj.irrActual
        const accAcpAll = obj.accAcpAll === null ? null : obj.accAcpAll
        download[0].data.push(revenueGoal)
        download[1].data.push(revenueActual)
        download[2].data.push(irrGoal)
        download[3].data.push(irrActual)
        download[4].data.push(accAcpAll)
      })

      this.revenueChart.xAxis.categories.forEach((x, xindex) => {
        downloadArr.forEach((y, yindex) => {
          y[`${x}`] = download[yindex].data[xindex]
        })
      })

      downloadCsv(downloadArr, fields, this.filename)
    }
  },
  watch: {
    dataClassArr: function (newValue, oldValue) {
      const setData = [
        {
          name: '發電收入',
          dataName: ['目標發電收入 (NTD)', '實際發電收入 (NTD)']
        },
        {
          name: '日照量',
          dataName: ['目標日照量 (kWh/m²)', '實際日照量 (kWh/m²)']
        },
        {
          name: '實際累積發電量',
          dataName: ['實際累積發電量 (kWh)']
        }
      ]
      if (oldValue !== newValue) {
        this.revenueChart.series.forEach((x) => {
          x.visible = false
        })
        setData.forEach((x) => {
          if (newValue.includes(x.name)) {
            x.dataName.forEach((y) => {
              this.revenueChart.series.find((z) => z.name === y).visible = true
            })
          }
        })
      }
    }
  }
}
</script>
